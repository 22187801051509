/* eslint-disable react/jsx-pascal-case */
import { Layout } from 'antd';
import React, { createContext,useState } from 'react';
import './App.css';
import HeaderDiv from './components/header';
import ContentDiv_by from './components/content_by';
import { Switch, Route } from 'react-router-dom';
import background from './images/back11.jpg'
import Factory from './components/factory';

const ExchangeContext = createContext();
const { Header, Footer, Content } = Layout;
const baseURL = ""

function App() {
  //const [account, setAccount] = useState(null);
  return (
    <ExchangeContext.Provider
      value={{
        ecc_addr: process.env.REACT_APP_ECC,
        usdt_addr: process.env.REACT_APP_USDT,
        sale_addr: process.env.REACT_APP_SALE,
        sale_ecc_addr: process.env.REACT_APP_SALE_ECC,
        nft_addr_1: process.env.REACT_APP_NFT_1,
        nft_addr_2: process.env.REACT_APP_NFT_2,
        nft_addr_3: process.env.REACT_APP_NFT_3
      }}
    >
      <Switch>
        <Layout>
          <Header>
            <HeaderDiv></HeaderDiv>
          </Header>
          <Route exact path='/factory'>
            <Content>
              <Factory />
            </Content>
          </Route>
          <Route exact path='/'>
            <Content>
              <ContentDiv_by></ContentDiv_by>
            </Content>
          </Route>
        </Layout>
      </Switch>
    </ExchangeContext.Provider>
  );
}

export { ExchangeContext };
export default App;
