import { ethers } from "ethers";

class MyWallet {

    constructor(){
        this.contracts = {}
        this.web3_provider = null
        this.account_callback = null;
        this.account = null;
    }

    handleAccountsChanged(accounts){
        if (accounts.length === 0) {
            // Wallet is locked or the user has not connected any accounts
            this.account = null;
            console.log('Please connect your wallet (Metamask, TrustWallet, etc).');
            if (this.account_callback != null) {
                this.account_callback(this.account);
            }
        } else {
            this.account = accounts[0];
            if (this.account_callback != null) {
                this.account_callback( this.account);
            }
        }
    }

    addContract (address, abi) {
        if (this.web3_provider != null) {
            if (this.contracts[address] === undefined) {
               // let 
                this.contracts[address] = new ethers.Contract(address, abi, this.web3_provider);
            }
        }
    }

    getBalance (address) {
        if (this.web3_provider != null) {
            return this.web3_provider.getBalance(address);
        }
    }

    getContract (address) {
        if (this.web3_provider != null) {
            if (this.contracts[address] !== undefined) {
                let contract_signer = this.contracts[address].connect(this.web3_provider.getSigner())
                return contract_signer
            }
        }
        return null;
    }

    getSigner () {
        if (this.web3_provider != null) {
            return this.web3_provider.getSigner()
        }
        return null;
    }

    async getGasPrice() {
        if (this.web3_provider != null) {
            return await this.web3_provider.getGasPrice();
        }
        return null;
    }

    async getGesParam(gas) {
        if (this.web3_provider != null) {
            if (typeof gas != 'undefined') {
                let gaslimit = Math.round(gas.toNumber() * 1.2);
                let res = await this.getGasPrice();
                if (typeof res !== 'undefined') {
                    let gasPrice = ethers.BigNumber.from(res.toString());
                    gasPrice = gasPrice.mul(12).div(10);
                    return {"gasLimit":gaslimit, "gasPrice":gasPrice}
                }
            }
        }
        return null
    }

    async connect () {
        if (this.web3_provider != null) {
            let _accounts = await this.web3_provider.send('eth_requestAccounts').catch((err) => {console.error(err)});
            if (typeof _accounts !== "undefined") {
                return _accounts[0];
            }
        }
        return null;
    }

    async getTransactionReceipt(tx) {
        let receipt = await this.web3_provider.getTransactionReceipt(tx).catch((err) => {console.error(err)});
        return receipt
    }

    async sendTransaction (_from, _to, _value, _msg) {
        if (this.web3_provider != null) {
            let _data = null;
            if (_msg.length > 0) {
                _data = ethers.utils.hashMessage(_msg);
            }

            const params = [{
                from: _from,
                to: _to,
                value: ethers.utils.parseUnits(_value, 'ether').toHexString()
            }];

            if (_data != null) {
                params[0].data = _data
            }
            return this.web3_provider.send('eth_sendTransaction', params);
        }
    }

    init(account_callback) {
        if (window.ethereum !== undefined) {
            this.account_callback = account_callback
            window.ethereum.on('accountsChanged', (accounts) => {
                this.handleAccountsChanged(accounts)
            });
            this.web3_provider = new ethers.providers.Web3Provider(window.ethereum);
            return true;
        }
        else {
            this.web3_provider = null;
            return false;
        }
    }
}

var myWallet = new MyWallet()

export default myWallet;