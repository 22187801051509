import React from 'react';
import './style.css';
import Banner01 from './Banner01';



function Content () {
    return (
        <>
            <Banner01></Banner01>
        </>
    )
}


export default Content;