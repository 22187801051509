/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useContext, useState, useRef} from 'react';
import './style.css';
import { ethers } from 'ethers';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExchangeContext } from '../../App';
import T2Token from '../../abi/T2Token.json';
import myWallet from '../mywallet';
import {LinkOutlined,DisconnectOutlined} from '@ant-design/icons';

const key = 'loading';

function Factory () {
    const { t, i18n } = useTranslation('common');
    const { ecc_addr, usdt_addr, nft_addr_1, nft_addr_2, nft_addr_3, sale_addr, sale_ecc_addr } = useContext(ExchangeContext);
    //const nftPrices_ref = useRef(null);
    //const nftPricesECC_ref = useRef(null);
    const loading_ref = useRef(false);
    const modalresult_ref = useRef(null);
    const [account, setAccount] = useState(null);
    

    const sleep = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const account_callback = _account => {
        if (_account) {
          setAccount(_account);
        } else {
            setAccount(null);
        }
    }

    

    const getSubStr = str => {
        var subStr1 = str.substr(0, 6);
        var subStr2 = str.substr(str.length - 4, 4);
        var subStr = subStr1 + '...' + subStr2;
        return subStr;
    }

    useEffect(() => {
        myWallet.init(account_callback);
        
    }, [])

    const onClickConnectWallet = async () => {
        let account = await myWallet.connect();
        account_callback(account)
    }

    const onDeploy = async () => {
        await onClickConnectWallet();
        const factory = new ethers.ContractFactory(T2Token.abi, T2Token.bytecode, myWallet.getSigner())
        const contract = await factory.deploy();
        console.log(contract.address);
        await contract.deployTransaction.wait();
    }

    return (
        <>
            <Button style={{marginTop:100}} onClick={onDeploy}>部署</Button>
        </>
    )
}


export default Factory;