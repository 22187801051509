/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import { Menu, Dropdown, Col, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import './style.css';
import {
    DownOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import logo from '../../images/FT_02.png';
import coin from '../../images/FT_coin01.png'
import coin01 from '../../images/阿拉伯.png'
import coin02 from '../../images/德国.png'
import coin03 from '../../images/俄罗斯.png'
import coin04 from '../../images/法国.png'
import coin05 from '../../images/美国.png'
import coin06 from '../../images/孟加拉.png'
import coin07 from '../../images/葡萄牙.png'
import coin08 from '../../images/日本.png'
import coin09 from '../../images/西班牙.png'
import coin10 from '../../images/意大利.png'
import myWallet from '../mywallet'
import { Link, useHistory } from 'react-router-dom';
const baseURL = ""


const MaskMenu = ({ visible, onClickItem }) => {
  const { t, i18n } = useTranslation('common');
  const history = useHistory();
  const [current, setCurrent] = useState("main");

  useEffect(()=> {
    history.listen((local, action) => {
      switch (local.pathname) {
        case "/":
            window.scrollTo(0, 0);
            setCurrent("main")
          break;
          case "/st":
            window.scrollTo(0, 0);
            setCurrent("st")
          break;
          case "/buy":
            window.scrollTo(0, 0);
            setCurrent("buy")
          break;
          case "/business":
            window.scrollTo(0, 0);
            setCurrent("business")
          break;
          case "/news":
            window.scrollTo(0, 0);
            setCurrent("news")
          break;
          case "/news_info":
            window.scrollTo(0, 0);
            setCurrent("news")
          break;
          case "/qa":
            window.scrollTo(0, 0);
            setCurrent("qa")
          break;
          case "/guide":
            window.scrollTo(0, 0);
            setCurrent("business")
          break;
          default:
          break;
      }
    })
  }, [])

  
  return (
    <div className={visible ? 'headermask' : 'hide'}>
      <Menu onClick={onClickItem} selectedKeys={''} mode="vertical" style={{width: 'auto',background:'#4b4b4b'}}>
        <Menu.Item key="main">
          <a style={{color:'white'}} href="https://www.future-ecc.com/#Overview">Overview</a>
        </Menu.Item>
        <Menu.Item key="st">
          <a style={{color:'white'}} href='https://www.future-ecc.com/#FuTureNFT'>FuTureNFT</a>
        </Menu.Item>
        <Menu.Item key="buy">
          <a style={{color:'white'}} href='https://www.future-ecc.com/#Roadmap'>Roadmap</a>
        </Menu.Item>
        <Menu.Item key="business">
          <a style={{color:'white'}} href='https://www.future-ecc.com/#Merchant'>Merchant</a>
        </Menu.Item>
        <Menu.Item key="news">
          <a style={{color:'white'}} href='https://www.future-ecc.com/#Token'>Token</a>
        </Menu.Item>
        <Menu.Item key="Community">
          <a style={{color:'white'}} href='https://www.future-ecc.com/#Community'>Community</a>
        </Menu.Item>
      </Menu>
    </div>
  );
};

function Header () {

    const { t, i18n } = useTranslation('common');
    const history = useHistory();
    const [current, setCurrent] = useState("main");
    const [visible, setVisible] = useState(false);
    const [address, setAddress] = useState(null);

    const handleClick = e => {
        setCurrent(e.key)
    };

    const getnationl = _lng => {
      switch (_lng) {
        case "cn":
          return coin;
        case "us":
          return coin05;
        case "it":
          return coin10;
        case "sp":
          return coin09;
        case "jp":
          return coin08;
        case "pg":
          return coin07;
        case "bl":
          return coin06;
        case "fc":
          return coin04;
        case "rs":
          return coin03;
        case "gm":
          return coin02;
        case "ab":
          return coin01;
        default:
          break;
      }
    };

    useEffect(()=> {
      // history.listen((local) => {
      //   switch (local.pathname) {
      //     case "/":
      //       window.scrollTo(0, 0);
      //       setCurrent("main")
      //     break;
      //     case "/st":
      //       window.scrollTo(0, 0);
      //       setCurrent("st")
      //     break;
      //     case "/buy":
      //       window.scrollTo(0, 0);
      //       setCurrent("buy")
      //     break;
      //     case "/business":
      //       window.scrollTo(0, 0);
      //       setCurrent("business")
      //     break;
      //     case "/news":
      //       window.scrollTo(0, 0);
      //       setCurrent("news")
      //     break;
      //     case "/news_info":
      //       window.scrollTo(0, 0);
      //       setCurrent("news")
      //     break;
      //     case "/qa":
      //       window.scrollTo(0, 0);
      //       setCurrent("qa")
      //     break;
      //     case "/guide":
      //       window.scrollTo(0, 0);
      //       setCurrent("business")
      //     break;
      //     default:
      //     break;
      //   }
      // })
    }, [])

    const onClickLanguage = _lng => {
      i18n.changeLanguage(_lng);
      localStorage.setItem("CURLNG",_lng)
    };

    const onClickItem = ({ item, key }) => {
      setVisible(false);
      document.body.style.overflow = 'auto';
    };

    const onClickSubMenu = e => {
      e.preventDefault();
      setVisible(!visible);
      if (visible) {
        document.body.style.overflow = 'auto';
      } else {
        document.body.style.overflow = 'hidden';
      }
    };

    const onClickConnect = async () => {
      let account = await myWallet.connect();
      setAddress(account)
    }

    const getSubStr = str => {
      var subStr1 = str.substr(0, 6);
      var subStr2 = str.substr(str.length - 4, 4);
      var subStr = subStr1 + '...' + subStr2;
      return subStr;
    }

    // const menu = (
    //     <Menu>
    //       <Menu.Item onClick={() => onClickLanguage('us')}>
    //         <span>English</span>
    //       </Menu.Item>
    //       <Menu.Item onClick={() => onClickLanguage('fc')}>
    //         <span>français</span>
    //       </Menu.Item>
    //       <Menu.Item onClick={() => onClickLanguage('gm')}>
    //         <span>Deutsch</span>
    //       </Menu.Item>
    //       <Menu.Item onClick={() => onClickLanguage('jp')}>
    //         <span>日本</span>
    //       </Menu.Item>
    //       <Menu.Item onClick={() => onClickLanguage('cn')}>
    //         <span>中文</span>
    //       </Menu.Item>
    //       <Menu.Item onClick={() => onClickLanguage('ab')}>
    //         <span>عربي</span>
    //       </Menu.Item>
    //       <Menu.Item onClick={() => onClickLanguage('rs')}>
    //         <span>русский</span>
    //       </Menu.Item>
    //       <Menu.Item onClick={() => onClickLanguage('bl')}>
    //         <span>বাংলা</span>
    //       </Menu.Item>
    //       <Menu.Item onClick={() => onClickLanguage('pg')}>
    //         <span>português</span>
    //       </Menu.Item>
    //       <Menu.Item onClick={() => onClickLanguage('sp')}>
    //         <span>español</span>
    //       </Menu.Item>
    //       <Menu.Item onClick={() => onClickLanguage('it')}>
    //         <span>italiano</span>
    //       </Menu.Item>
    //     </Menu>
    // );

    return (
        <>
          <div className='Hp_header'>
            <Row style={{width:'100%'}}>
              <Col span={0} md={24}>
                <div className='content flex_space-between_center' style={{padding:10}}>
                    <a className='logo' href="https://www.future-ecc.com">
                      <img src={logo} style={{width:150}}></img>
                    </a>
                    <nav migration_allowed="1" migrated="0" role="navigation" className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none">
                      <ul id="menu-1-8d7ce67" className="elementor-nav-menu" data-smartmenus-id="16601027815280369"><li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-10"><a href="https://www.future-ecc.com/#Overview" aria-current="page" className="elementor-item elementor-item-anchor">Overview</a></li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-11"><a href="https://www.future-ecc.com/#FuTureNFT" aria-current="page" className="elementor-item elementor-item-anchor">FuTureNFT</a></li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-12"><a href="https://www.future-ecc.com/#Roadmap" aria-current="page" className="elementor-item elementor-item-anchor">Roadmap</a></li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-13"><a href="https://www.future-ecc.com/#Merchant" aria-current="page" className="elementor-item elementor-item-anchor">Merchant</a></li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-14"><a href="https://www.future-ecc.com/#Token" aria-current="page" className="elementor-item elementor-item-anchor">Token</a></li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-15"><a href="https://www.future-ecc.com/#Community" aria-current="page" className="elementor-item elementor-item-anchor">Community</a></li>
                      </ul>
                    </nav>
                    <Button shape="round" onClick={onClickConnect} type="primary">
                      {
                        address === null ? "CONNECT WALLET" : getSubStr(address)
                      }
                    </Button>
                </div>
              </Col>
              <Col span={24} md={0}>
                <Row style={{width:'100%'}} justify='space-between'>
                  <Col span={3}>
                    <div className='logo'>
                      <img src={logo}></img>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                      {/* <div className='lang_e'>
                        <img src={getnationl(i18n.language)} style={{width:22,height:22}}></img>
                        <Dropdown overlay={menu} className="language_menu">
                            <a
                            style={{ color: '#000', fontSize: 16, fontWeight: 'bold', color:'white', marginLeft:10}}
                            className="ant-dropdown-link"
                            onClick={e => e.preventDefault()}
                            >
                            {t('language')} <DownOutlined />
                            </a>
                        </Dropdown>
                      </div> */}
                      <div>
                        <Button shape="round" onClick={onClickConnect} type="primary">
                        {
                          address === null ? "CONNECT WALLET" : getSubStr(address)
                        }
                        </Button>
                      </div>
                      <div className="submenu" onClick={onClickSubMenu}>
                        <UnorderedListOutlined style={{ fontSize: 24, color: 'white' }} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <MaskMenu visible={visible} onClickItem={onClickItem} />
        </>
        
    )
}


export default Header;