/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useContext, useState, useRef} from 'react';
import './style.css';
import { ethers } from 'ethers';
import cookie from 'react-cookies'
// import top from '../../../images/GM_01.png';
// import cd01 from '../../../images/FT_07.png';
// import cd02 from '../../../images/FT_06.png';
// import cd03 from '../../../images/FT_05.png';
import cd01a from '../../../images/FT_07a.png';
import cd02a from '../../../images/FT_06a.png';
import cd03a from '../../../images/FT_05a.png';
import coin from '../../../images/GM_05.png';
import symbol from '../../../images/symbol_01.png';
import floor_btn01 from '../../../images/by01.png';
import floor_btn02 from '../../../images/by02.png';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExchangeContext } from '../../../App';
import usdt_abi from '../../../abi/ERC20.json';
import nft_abi from '../../../abi/FutureNFT.json';
import sale_abi from '../../../abi/FutureNFTSale.json';
import sale_ecc_abi from '../../../abi/FutureNFTSaleToken.json';
import myWallet from '../../mywallet';
import {LinkOutlined,DisconnectOutlined} from '@ant-design/icons';

const key = 'loading';

function Banner01 () {
    const { t, i18n } = useTranslation('common');
    const { ecc_addr, usdt_addr, nft_addr_1, nft_addr_2, nft_addr_3, sale_addr, sale_ecc_addr } = useContext(ExchangeContext);
    //const nftPrices_ref = useRef(null);
    //const nftPricesECC_ref = useRef(null);
    const loading_ref = useRef(false);
    const modalresult_ref = useRef(null);
    const [account, setAccount] = useState(null);
    const [price_1_usdt, setPrice_1_usdt] = useState("9000");
    const [price_2_usdt, setPrice_2_usdt] = useState("45000");
    const [price_3_usdt, setPrice_3_usdt] = useState("90000");
    const [price_1_ecc, setPrice_1_ecc] = useState("8800");
    const [price_2_ecc, setPrice_2_ecc] = useState("44000");
    const [price_3_ecc, setPrice_3_ecc] = useState("88000");
    const [modal_title, setModal_title] = useState("-");
    const [modal_worth, setModal_worth] = useState("-");
    const [modal_price, setModal_price] = useState("-");
    const [modal_rate, setModal_rate] = useState("-");
    const [modal_ecc, setModal_ecc] = useState("-");
    const [modal_approve, setModal_approve] = useState("-");
    const [modal_show, setModal_show] = useState(0);
    const [modal_type, setModal_type] = useState(0);
    const [model_isApprove, setModal_isApprove] = useState(false);

    const sleep = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const account_callback = _account => {
        if (_account) {
          setAccount(_account);
        } else {
            setAccount(null);
        }
    }

    const approve = async (token, spender, amount) => {
        let gas = await token.estimateGas.approve(spender, amount).catch((err) => {
            console.error(err)
        });
        if (typeof gas != 'undefined') {
            let gaslimit = Math.round(gas.toNumber() * 1.5);
            let tx = await token.approve(spender, amount, {"gasLimit":gaslimit}).catch((err) => {console.error(err)});
            return tx;
        }
    };

    const mintPublic = async (sale_nft, amount, quantity, nft_addr) => {
        let gas = await sale_nft.estimateGas.mintPublic(amount, quantity, nft_addr).catch((err) => {
            console.error(err)
        });
        if (typeof gas != 'undefined') {

            let gaslimit = await myWallet.getGesParam(gas);
            if (gaslimit) {
                let tx = await sale_nft.mintPublic(amount, quantity, nft_addr, gaslimit).catch((err) => {console.error(err)});
                return tx;
            }
        }
    }
    
    const checkTxcheck = async (hash) => {
        let times = 30;
        while (times > 0) {
            let receipt = await myWallet.getTransactionReceipt(hash);
            if (receipt != null) {
                return receipt.status
            }
            await sleep(1000)
            times = times -1;
        }
    };

    const onClick_BuyNFT = async (title, worth, _nft_addr, type) => {
        if (loading_ref.current) return;
        message.loading({ content: 'waiting ...', key , duration:0});
        loading_ref.current = true;
        let account = await myWallet.connect();
        if (account === null) {
            message.error({ content: 'connect wallect failed!', key});
            loading_ref.current = false;
            return;
        }
        let result = await update_balance(account, type);
        if (typeof result === 'object') {
            setModal_type(type)
            if (type === 1) {  // ecc
                result.nft_addr = _nft_addr
                result.type = type
                setModal_title(title)
                setModal_worth(worth)
                setModal_isApprove(false);
                let price = result[_nft_addr].price
                setModal_price(ethers.utils.formatEther(price) + " USDT")
                let rate = parseFloat(ethers.utils.formatEther(result.ecc_rate)).toFixed(4)
                setModal_rate("1 USDT ≈ " + rate + " ECC")
                let price_ecc = parseFloat(ethers.utils.formatEther(result[_nft_addr].price_ecc)).toFixed(1)
                setModal_ecc("≈ " + price_ecc + " ECC")
                //let approve = parseFloat(ethers.utils.formatEther(result[_nft_addr].approve_ecc)).toFixed(1)
                setModal_approve(t("buy.approve"))
                modalresult_ref.current = result
                setModal_show(1);
                message.destroy(key)
                loading_ref.current = false;
            }
            else {  // usdt
                result.nft_addr = _nft_addr
                result.type = type
                setModal_title(title)
                setModal_worth(worth)
                setModal_isApprove(false);
                let price = result[_nft_addr].price
                setModal_price(ethers.utils.formatEther(price) + " USDT")
                //let approve = parseFloat(ethers.utils.formatEther(price)).toFixed(1)
                setModal_approve(t("buy.approve"))
                modalresult_ref.current = result
                setModal_show(1);
                message.destroy(key)
                loading_ref.current = false;
            }
        }
        else {
            message.error({ content: 'update_balance is error!', key});
            loading_ref.current = false;
        }
    }

    const onClickModal_Approve = async () => {
        if (loading_ref.current) return;
        message.loading({ content: 'waiting approve ...', key , duration:0});
        loading_ref.current = true;

        let account = await myWallet.connect();
        if (account === null) {
            message.error({ content: 'connect wallect failed!', key});
            loading_ref.current = false;
            return;
        }

        if (modalresult_ref.current !== null) {
            let type = modalresult_ref.current.type
            let nft_addr = modalresult_ref.current.nft_addr
            let token = null;
            let sale_nft = null;
            let approvePrice = null;
            if (type === 1) { //sale_ecc
                token = myWallet.getContract(ecc_addr);
                sale_nft = myWallet.getContract(sale_ecc_addr);
                approvePrice = modalresult_ref.current[nft_addr].approve_ecc
            }
            else { //sale_usdt
                token = myWallet.getContract(usdt_addr);
                sale_nft = myWallet.getContract(sale_addr);
                approvePrice = modalresult_ref.current[nft_addr].price
            }

            let balance = await token.balanceOf(account).catch(err => {console.error(err)});
            let allowance = await token.allowance(account, sale_nft.address).catch(err => {console.error(err)});
            if (typeof balance !== "undefined" && typeof allowance !== "undefined") {
                if (balance.gte(approvePrice)) { // balance >= _price
                    if (allowance.lt(approvePrice)) { // allowance < _price
                        let tx = await approve(token, sale_nft.address, approvePrice);
                        if ( typeof tx !== "undefined") {
                            let status = await checkTxcheck(tx.hash);
                            if (status === 1) {
                                message.success({ content: 'approve is Successed!', key});
                                setModal_isApprove(true);
                                loading_ref.current = false;
                                return
                            }
                            else {
                                message.error({ content: 'approve is failed!', key});
                                loading_ref.current = false;
                                return
                            }
                        }
                        else {
                            message.error({ content: 'approve is failed!', key});
                            loading_ref.current = false;
                            return
                        }
                    }
                    else {
                        message.success({ content: 'approve is Successed!', key});
                        setModal_isApprove(true);
                        loading_ref.current = false;
                        return
                    }
                }
                else {
                    if (type === 1) { //sale_ecc
                        message.error({ content: 'ECC balance is not enough', key});
                        loading_ref.current = false;
                        return
                    }
                    else { //sale_usdt
                        message.error({ content: 'USDT balance is not enough', key});
                        loading_ref.current = false;
                        return
                    }
                }
            }
        }
        else {
            message.error({ content: 'result is error!', key});
            loading_ref.current = false;
            return
        }
    }

    const onClickModal_Buy = async () => {
        if (loading_ref.current) return;
        message.loading({ content: 'waiting buy ...', key , duration:0});
        loading_ref.current = true;

        let account = await myWallet.connect();
        if (account === null) {
            message.error({ content: 'connect wallect failed!', key});
            loading_ref.current = false;
            return;
        }

        if (modalresult_ref.current !== null) {
            let type = modalresult_ref.current.type
            let nft_addr = modalresult_ref.current.nft_addr
            let sale_nft = null;
            let approvePrice = null;
            if (type === 1) { //sale_ecc
                sale_nft = myWallet.getContract(sale_ecc_addr);
                approvePrice = modalresult_ref.current[nft_addr].approve_ecc
            }
            else { //sale_usdt
                sale_nft = myWallet.getContract(sale_addr);
                approvePrice = modalresult_ref.current[nft_addr].price
            }

            let tx2 = await mintPublic(sale_nft, approvePrice, 1, nft_addr);
            if ( typeof tx2 !== "undefined") {
                let status2 = await checkTxcheck(tx2.hash);
                if (status2 === 1) {
                    message.success({ content: 'FNT Mint Successed!', key});
                    loading_ref.current = false;
                    setModal_show(0)
                    return
                }
                else {
                    message.error({ content: 'FNT Mint faied!', key});
                    loading_ref.current = false;
                    return
                }
            }
            else {
                message.error({ content: 'FNT mintPublic is failed!', key});
                loading_ref.current = false;
                return
            }
        }
        else {
            message.error({ content: 'result is error!', key});
            loading_ref.current = false;
            return
        }
    }

    const update_balance = async (_account, type) => {
        if (_account == null) return;

        if (type === 1) {
            let sale_nft_ecc = myWallet.getContract(sale_ecc_addr);
            if (sale_nft_ecc !== null) {
                let result = {}
                let _price1 = await sale_nft_ecc.priceMap(nft_addr_1).catch(err => {console.error(err)});
                if (typeof _price1 !== "undefined") {
                    setPrice_1_ecc(ethers.utils.formatEther(_price1));
                    result[nft_addr_1] = {price:_price1}
                    let _price_ecc = await sale_nft_ecc.getRealtimePrice(1, nft_addr_1).catch(err => {console.error(err)});
                    if (typeof _price_ecc !== "undefined") {
                        result[nft_addr_1].price_ecc = _price_ecc
                        result[nft_addr_1].approve_ecc = _price_ecc.mul(1005).div(1000);
                    }
                }
    
                let _price2 = await sale_nft_ecc.priceMap(nft_addr_2).catch(err => {console.error(err)});
                if (typeof _price2 !== "undefined") {
                    console.log(ethers.utils.formatEther(_price2))
                    setPrice_2_ecc(ethers.utils.formatEther(_price2));
                    result[nft_addr_2] = {price:_price2}
                    let _price_ecc = await sale_nft_ecc.getRealtimePrice(1, nft_addr_2).catch(err => {console.error(err)});
                    if (typeof _price_ecc !== "undefined") {
                        result[nft_addr_2].price_ecc = _price_ecc
                        result[nft_addr_2].approve_ecc = _price_ecc.mul(1005).div(1000);
                        console.log(ethers.utils.formatEther(_price_ecc.mul(1005).div(1000)))
                    }
                }
    
                let _price3 = await sale_nft_ecc.priceMap(nft_addr_3).catch(err => {console.error(err)});
                if (typeof _price3 !== "undefined") {
                    setPrice_3_ecc(ethers.utils.formatEther(_price3));
                    result[nft_addr_3] = {price:_price3}
                    let _price_ecc = await sale_nft_ecc.getRealtimePrice(1, nft_addr_3).catch(err => {console.error(err)});
                    if (typeof _price_ecc !== "undefined") {
                        result[nft_addr_3].price_ecc = _price_ecc
                        result[nft_addr_3].approve_ecc = _price_ecc.mul(1005).div(1000);
                    }
                }
    
                let ecc_rate = await sale_nft_ecc.getAmountIn(ethers.utils.parseEther("1")).catch(err => {console.error(err)});
                if (typeof ecc_rate !== "undefined") {
                    result.ecc_rate = ecc_rate
                }
                return result
            }
        }
        else {
            let sale_nft = myWallet.getContract(sale_addr);
            if (sale_nft != null) {
                let result = {}
                let _price1 = await sale_nft.priceMap(nft_addr_1).catch(err => {console.error(err)});
                if (typeof _price1 !== "undefined") {
                    setPrice_1_usdt(ethers.utils.formatEther(_price1));
                    result[nft_addr_1] = {price:_price1}
                }
    
                let _price2 = await sale_nft.priceMap(nft_addr_2).catch(err => {console.error(err)});
                if (typeof _price2 !== "undefined") {
                    setPrice_2_usdt(ethers.utils.formatEther(_price2));
                    result[nft_addr_2] = {price:_price2}
                }
    
                let _price3 = await sale_nft.priceMap(nft_addr_3).catch(err => {console.error(err)});
                if (typeof _price3 !== "undefined") {
                    setPrice_3_usdt(ethers.utils.formatEther(_price3));
                    result[nft_addr_3] = {price:_price3}
                }
    
                return result
            }
        }
    };

    const getSubStr = str => {
        var subStr1 = str.substr(0, 6);
        var subStr2 = str.substr(str.length - 4, 4);
        var subStr = subStr1 + '...' + subStr2;
        return subStr;
    }

    useEffect(() => {
        myWallet.init(account_callback);
        myWallet.addContract(ecc_addr, usdt_abi.abi);
        myWallet.addContract(usdt_addr, usdt_abi.abi);
        myWallet.addContract(nft_addr_1, nft_abi.abi);
        myWallet.addContract(nft_addr_2, nft_abi.abi);
        myWallet.addContract(nft_addr_3, nft_abi.abi);
        myWallet.addContract(sale_addr, sale_abi.abi);
        myWallet.addContract(sale_ecc_addr, sale_ecc_abi.abi);
    }, [])

    const onClickConnectWallet = async () => {
        let account = await myWallet.connect();
        account_callback(account)
    }


    // const setCookie = (key, body) => {
    //     const expires = new Date(new Date().getTime() + 24 * 3600 * 1000); //一天
    //     cookie.save(key, body, {
    //         path: '/',
    //         expires
    //     });
    //     //ookie.save(key, body, {expires})
    // }

    return (
        <>
            <div className='Banner01_by'>
                <div className='top_btn'>
                    <span>Purchase FuTureNFT</span>              
                </div>
                <div className='main'>
                    <div className='box'>
                        <div className='top'>
                            <span>{t("buy.USDT")}</span>
                        </div>
                        <div className='bom'>
                            <div>
                                <div className='cd'> 
                                    <img src={cd01a}></img>
                                    <div>
                                        <img src={coin}></img>
                                        <div>
                                            <span>{i18n.language === 'cn' ? 9 : 10}</span>
                                            <span className={ i18n.language !== 'cn' ? 'hide': ''}>折</span>
                                            <img src={symbol} className={ i18n.language === 'cn' ? 'hide': ''}></img>
                                        </div>
                                    </div>
                                </div>
                                <span>{t("buy.name_card01a")}</span>
                                <span>{t("buy.USDT_card01b")}</span>
                                <span className='usdt'>{t("buy.price") + " : " + price_1_usdt + " USDT"}</span>
                                <Button  type="primary" 
                                    onClick={(e)=>{onClick_BuyNFT(t("buy.name_card01a"), "$ 10,000", nft_addr_1, 0)}}
                                    >{t("General_btn.buy")}</Button>
                            </div>
                            <div>
                                <div className='cd'> 
                                    <img src={cd02a}></img>
                                    <div>
                                        <img src={coin}></img>
                                        <div>
                                            <span>{i18n.language === 'cn' ? 9 : 10}</span>
                                            <span className={ i18n.language !== 'cn' ? 'hide': ''}>折</span>
                                            <img src={symbol} className={ i18n.language === 'cn' ? 'hide': ''} ></img>  
                                        </div>
                                    </div>
                                </div>
                                <span>{t("buy.name_card02a")}</span>
                                <span>{t("buy.USDT_card02b")}</span>
                                <span className='usdt'>{t("buy.price") + " : " + price_2_usdt + " USDT"}</span>
                                <Button  type="primary" 
                                    onClick={(e)=>{onClick_BuyNFT(t("buy.name_card02a"), "$ 50,000", nft_addr_2, 0)}}
                                    >{t("General_btn.buy")}</Button>
                            </div>
                            <div>
                                <div className='cd'> 
                                    <img src={cd03a}></img>
                                    <div>
                                        <img src={coin}></img>
                                        <div>
                                            <span>{i18n.language === 'cn' ? 9 : 10}</span>
                                            <span className={ i18n.language !== 'cn' ? 'hide': ''}>折</span>
                                            <img src={symbol} className={ i18n.language === 'cn' ? 'hide': ''}></img>  
                                        </div>
                                    </div>
                                </div>
                                <span>{t("buy.name_card03a")}</span>
                                <span>{t("buy.USDT_card03b")}</span>
                                <span className='usdt'>{t("buy.price") + " : " + price_3_usdt + " USDT"}</span>
                                <Button  type="primary" 
                                    onClick={(e)=>{onClick_BuyNFT(t("buy.name_card03a"), "$ 100,000", nft_addr_3, 0)}}
                                    >{t("General_btn.buy")}</Button>
                            </div>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='top'>
                            <span>{t("buy.ECC")}</span>
                        </div>
                        <div className='bom'>
                            <div>
                                <div className='cd'> 
                                    <img src={cd01a}></img>
                                    <div>
                                        <img src={coin}></img>
                                        <div>
                                            <span>{i18n.language === 'cn' ? 8.8 : 12}</span>
                                            <span className={ i18n.language !== 'cn' ? 'hide': ''}>折</span>
                                            <img src={symbol} className={ i18n.language === 'cn' ? 'hide': ''}></img>  
                                        </div>
                                    </div>
                                </div>
                                <span>{t("buy.name_card01a")}</span>
                                <span>{t("buy.ECC_card01b")}</span>
                                <span className='usdt'>{t("buy.price") + " : " + price_1_ecc + " USDT"}</span>
                                <Button  type="primary" 
                                    onClick={(e)=>{onClick_BuyNFT(t("buy.name_card01a"), "$ 10,000", nft_addr_1, 1)}}
                                    >{t("General_btn.buy")}</Button>
                            </div>
                            <div>
                                <div className='cd'> 
                                    <img src={cd02a}></img>
                                    <div>
                                        <img src={coin}></img>
                                        <div>
                                            <span>{i18n.language === 'cn' ? 8.8 : 12}</span>
                                            <span className={ i18n.language !== 'cn' ? 'hide': ''}>折</span>
                                            <img src={symbol} className={ i18n.language === 'cn' ? 'hide': ''}></img>  
                                        </div>
                                    </div>
                                </div>
                                <span>{t("buy.name_card02a")}</span>
                                <span>{t("buy.ECC_card02b")}</span>
                                <span className='usdt'>{t("buy.price") + " : " + price_2_ecc + " USDT"}</span>
                                <Button  type="primary" 
                                    onClick={(e)=>{onClick_BuyNFT(t("buy.name_card02a"), "$ 50,000", nft_addr_2, 1)}}
                                    >{t("General_btn.buy")}</Button>
                            </div>
                            <div>
                                <div className='cd'> 
                                    <img src={cd03a}></img>
                                    <div>
                                        <img src={coin}></img>
                                        <div>
                                            <span>{i18n.language === 'cn' ? 8.8 : 12}</span>
                                            <span className={ i18n.language !== 'cn' ? 'hide': ''}>折</span>
                                            <img src={symbol} className={ i18n.language === 'cn' ? 'hide': ''}></img>  
                                        </div>
                                    </div>
                                </div>
                                <span>{t("buy.name_card03a")}</span>
                                <span>{t("buy.ECC_card03b")}</span>
                                <span className='usdt'>{t("buy.price") + " : " + price_3_ecc + " USDT"}</span>
                                <Button  type="primary" 
                                    onClick={(e)=>{onClick_BuyNFT(t("buy.name_card03a"), "$ 100,000", nft_addr_3, 1)}}
                                    >{t("General_btn.buy")}</Button>
                            </div>
                        </div>
                    </div>                
                </div>
                <div className='bottom'>
                    <span>NOTES</span>
                    <p>-Download a decentralized wallet that supports BSC-20 tokens, such as MetaMask or Coinbase</p>
                    <p>-Use your wallet to purchase FuTureNFT under the condition that you have sufficient USDT/ECC</p>
                    <p>-Connect your wallet to FuTure</p>
                    <p>-Choose the suitable FNT and click to purchase</p>
                    <p>-Your wallet sends a prompt requesting you to click "Confirm"</p>
                    <p>-FuTureNFTs are transferred to your wallet</p>
                </div>
                {/* <div className='floor'>
                    <div className='up'>
                        <span>Join FuTureNFT</span>
                        <span>Become a member of this revolution in the regenerative medicine</span>
                        <div>
                            <div className='left'>
                                <div>
                                    <a href="https://t.me/future_fnt">
                                        <img src={floor_btn01}></img>
                                    </a>
                                </div>
                                <div>
                                    <a href="https://twitter.com/fnt_future">
                                        <img src={floor_btn02}></img>
                                    </a>
                                </div>
                            </div>
                            <div className='right'>
                                <div>
                                    <a href="/wp-content/uploads/2022/08/3bfb091b91.pdf" style={{target:"_blank"}}>
                                        <span className='floor_txt_btn' style={{color:"white"}} dangerouslySetInnerHTML={{__html:'White<br/>paper'}}></span>
                                    </a>
                                </div>
                                <div style={{background:"white"}}>
                                    <a href="/cwsj/">
                                        <span className='floor_txt_btn' style={{color:"#1a51b5"}} dangerouslySetInnerHTML={{__html:'Become a<br/>merchant'}}></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='down'>
                        <span>All Rights Reserved © FuTureNFT 2022</span>
                        <span>Terms & Conditions</span>
                    </div>  
                </div> */}
                <div className='ModelBuy' show={modal_show} onClick={() => {
                        if (loading_ref.current) return;
                        setModal_show(0)
                    }}>
                    <div className='main main_modal'>
                        <div className='box' style={{padding:10,background:'white'}} onClick={(e)=>{e.stopPropagation()}}>
                            <div className='top modalTop'>
                                <span>{modal_title}</span>
                            </div>
                            <div className='bom'>
                                <div className='line'>
                                    <span>{t("buy.worth")}</span>
                                    <span>{modal_worth}</span>
                                </div>
                                <div className='line'>
                                    <span>{t("buy.price")}</span>
                                    <span>{modal_price}</span>
                                </div>
                                {
                                    modal_type === 1 ?
                                    <>
                                        <div className='line' style={{marginTop:10}}>
                                            <span style={{fontWeight:'initial'}}>{t("buy.rate")}</span>
                                            <span style={{fontWeight:'initial'}}>{modal_rate}</span>
                                        </div>
                                        <div className='line'>
                                            <span style={{fontWeight:'initial'}}>{t("buy.equivalent_to")}</span>
                                            <span style={{fontWeight:'initial'}}>{modal_ecc}</span>
                                        </div>
                                    </> : ''
                                }
                                <Button style={{marginTop:20}} disabled={model_isApprove} type="primary" block onClick={onClickModal_Approve}>{modal_approve}</Button>
                                <Button style={{marginTop:10}} disabled={!model_isApprove} type="primary" block onClick={onClickModal_Buy}>{t("General_btn.buy")}</Button>
                            </div>
                        </div>               
                    </div>
                </div>
            </div>
        </>
    )
}


export default Banner01;