import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_cn from './locakes/translation_cn.json'
import translation_en from './locakes/translation_en.json'
import translation_Arabic from './locakes/translation_Arabic.json'
import translation_Bengali from './locakes/translation_Bengali.json'
import translation_French from './locakes/translation_French.json'
import translation_German from './locakes/translation_German.json'
import translation_Italy from './locakes/translation_Italy.json'
import translation_Japanese from './locakes/translation_Japanese.json'
import translation_Portuguese from './locakes/translation_Portuguese.json'
import translation_Russian from './locakes/translation_Russian.json'
import translation_Spanish from './locakes/translation_Spanish.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  cn: {
    common: translation_cn
  },
  us: {
    common: translation_en
  },
  it: {
    common: translation_Italy
  },
  sp: {
    common: translation_Spanish
  },
  jp: {
    common: translation_Japanese
  },
  pg: {
    common: translation_Portuguese
  },
  bl: {
    common: translation_Bengali
  },
  fc: {
    common: translation_French
  },
  rs: {
    common: translation_Russian
  },
  gm: {
    common: translation_German
  },
  ab: {
    common: translation_Arabic
  },
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'us',

    // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
